<template>
    <div class="entries">
        <h3>All Entries</h3>
    </div>
</template>

<script>
export default {
    name: "entries",

    props: {},

    components: {},

    data() {
        return {};
    },

    computed: {},

    methods: {},

    async mounted() {},
};
</script>

<style lang="scss"></style>
